// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-data-tsx": () => import("./../src/pages/data.tsx" /* webpackChunkName: "component---src-pages-data-tsx" */),
  "component---src-pages-details-tsx": () => import("./../src/pages/details.tsx" /* webpackChunkName: "component---src-pages-details-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

